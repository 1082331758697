import styled from 'styled-components'

export interface StyledDropDownProps {
  $disabled?: boolean
}

export const StyledDropDownSearch = styled.div<StyledDropDownProps>`
  ${({ theme, $disabled }) => `
      pointer-events: ${$disabled ? 'none' : 'auto'};
      text-align: left;
      color: ${theme.colors.black};
      display: flex;
      margin: ${theme.sizes.s};

      label {
        display: flex;
      }
      input {
        pointer-events: ;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        min-width: 0;
        padding: ${theme.sizes.xs};
        padding-left: ${theme.sizes.xl};

        border-radius: ${theme.borderRadii.medium};
        border: 0.0625rem solid ${theme.colors.gray80};

        &:hover {
          border: 0.0625rem solid ${theme.colors.black};
          outline: ${theme.sizes[1]} solid ${theme.colors.gray80};
        }
        &:focus,
        &:active {
          border: 0.0625rem solid ${theme.colors.black};
        }

      }
    `}
`

export const StyledIconContainer = styled.div`
  ${({ theme }) => `
    position: absolute;
    z-index: 1;
    margin: ${theme.sizes[2]};
  `}
`
