import React from 'react';
import { useTheme } from 'styled-components';
import { Theme } from '../../theme';
import { SpacerProps } from './spacer.types';

export const Spacer: React.FC<SpacerProps> = ({
  size,
  axis = 'horizontal',
}) => {
  const theme = useTheme() as Theme;
  const sizeVariable = theme.sizes[size];
  const display = axis === 'horizontal' ? 'inline-block' : 'block';
  const width = axis === 'vertical' ? 1 : sizeVariable;
  const height = axis === 'horizontal' ? 1 : sizeVariable;

  return (
    <span
      style={{
        display: display,
        width,
        minWidth: width,
        height,
        minHeight: height,
      }}
    />
  );
};
