import { Icon } from '../icon'
import styled, { css } from 'styled-components'

export const FakeCheckboxContainer = styled.span<{ isSelected: boolean }>`
  ${({ theme, isSelected }) => css`
    width: ${theme.sizes[5]};
    height: ${theme.sizes[5]};
    cursor: pointer;
    transition: all ${theme.transitionTime};
    position: relative;
    display: block;
    &::before {
      content: '';
      z-index: -1 !important;
      display: inline-block;
      box-sizing: border-box;
      width: ${theme.sizes[5]};
      height: ${theme.sizes[5]};
      border-radius: ${theme.borderRadii.small};
      border: 0.15rem solid
        ${isSelected ? theme.colors.primary : theme.colors.gray300};
      background: ${isSelected ? theme.colors.primary : 'transparent'};
      pointer-events: none;
      transition: all ${theme.transitionTime};
    }

    &:hover&::before {
      border-color: ${theme.colors.primary};
      box-shadow: ${theme.colors.primary100} 0px 0px 0px ${theme.sizes[1]};
    }

    > svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  `}
`

export const FakeCheckbox = ({
  isSelected,
  selectedTestId,
}: {
  isSelected: boolean
  selectedTestId?: string
}) => (
  <FakeCheckboxContainer isSelected={isSelected}>
    {isSelected && (
      <Icon
        name="check"
        size={4}
        color="white"
        customTestId={selectedTestId || 'fake-checkbox-selected'}
      />
    )}
  </FakeCheckboxContainer>
)
