import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from '../button'
import { Text } from '../text'
import { Feedback } from '../feedback'
import { Label } from '../label'
import { FileInputProps } from './file-input.types'
import { ReactComponent as PlaceholderLogo } from '../../assets/images/icons/placeholder-image.svg'

const StyledFileInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.sizes.xs};
  width: 100%;
`

const StyledFileInput = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.sizes.xs};
`

const StyledPreviewImageBox = styled.div`
  ${({ theme }) =>
    `
      width: 220px;
      height: 125px;
      border-radius: ${theme.borderRadii.large};
      overflow: hidden;
      border: 1px solid ${theme.colors.gray80};
  `}
`

const StyledPreviewPlaceholder = styled.div`
  ${({ theme }) =>
    ` 
    width: 220px;
    height: 125px;
    border-radius: ${theme.borderRadii.large};
    overflow: hidden;
    background-color: ${theme.colors.gray80};
    border: 1px solid ${theme.colors.gray80};
    justify-content: center;
    align-items: center;
    display: flex;`})
`

const StyledPreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const getFilesFromFileList = (fileList: FileList) =>
  Array.from(Array(fileList.length)).map((_, i) => fileList.item(i)) as File[]

const getFilesNames = (files: File[]) =>
  Object.values(files)
    .map((file) => file.name)
    .join(', ')

const getInitialFiles = (file?: File | File[] | null) => {
  if (!file) {
    return []
  } else if (Array.isArray(file)) {
    return file
  } else {
    return [file]
  }
}

export const FileInput = ({
  label,
  accept,
  placeholder,
  onChange,
  onChangeForMultiple,
  errorMessage,
  dataTestId,
  file,
  fileUploadLabel = 'Choose file',
  showPreview,
  allowMultiple = false,
  initialImage,
}: FileInputProps) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null)
  const [selectedFiles, setSelectedFiles] = useState<File[]>(
    getInitialFiles(file)
  )
  const [preview, setPreview] = useState<string | undefined>(undefined)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      if (allowMultiple) {
        const files = getFilesFromFileList(event.target.files)

        setSelectedFiles(files)
        onChangeForMultiple?.(files)
      } else {
        const file = event.target.files[0]

        setSelectedFiles([file])
        onChange?.(file)
      }
    }
  }

  useEffect(() => {
    if (!selectedFiles || selectedFiles.length === 0) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFiles[0])
    setPreview(objectUrl)

    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFiles])

  return (
    <StyledFileInputContainer>
      {label ? <Label htmlFor="file-input">{label}</Label> : null}
      {errorMessage ? (
        <Feedback align="start" variant="critical">
          {errorMessage}
        </Feedback>
      ) : null}
      <StyledFileInput>
        {showPreview && !selectedFiles[0] && !initialImage ? (
          <StyledPreviewPlaceholder data-testid="preview-placeholder">
            <PlaceholderLogo />
          </StyledPreviewPlaceholder>
        ) : null}
        {showPreview && !allowMultiple && selectedFiles[0] ? (
          <StyledPreviewImageBox>
            <StyledPreviewImage src={preview} alt={selectedFiles[0].name} />
          </StyledPreviewImageBox>
        ) : showPreview && initialImage ? (
          <StyledPreviewImageBox>
            <StyledPreviewImage src={initialImage} alt={initialImage} />
          </StyledPreviewImageBox>
        ) : null}
        <Button
          variant="secondary"
          onPress={() => hiddenFileInput.current?.click()}
          size="small"
        >
          {fileUploadLabel}
        </Button>
        {selectedFiles.length > 0 ? (
          <Text as="span" size="small" overflowEllipsis={true}>
            <b>{getFilesNames(selectedFiles)}</b>
          </Text>
        ) : null}

        {placeholder && !selectedFiles[0] ? (
          <Feedback variant="default">{placeholder}</Feedback>
        ) : null}
      </StyledFileInput>

      <input
        type="file"
        id="file-input"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        accept={accept}
        multiple={allowMultiple}
        data-testid={dataTestId}
      />
    </StyledFileInputContainer>
  )
}
