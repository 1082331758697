import { createTheme, ThemeProvider } from '@mui/material'

import { ThemeContextProps } from './ThemeProvider.types'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

export function ThemeContext({ children }: ThemeContextProps) {
  const { theme: themeColorPalette } = useColorTheme()

  const palette = {
    primary: { main: themeColorPalette.primary400 },
    secondary: { main: themeColorPalette.black },
    text: {
      primary: themeColorPalette.gray200,
      secondary: themeColorPalette.gray200
    },
    clientColor: themeColorPalette.primary,
    detectedTextColor: themeColorPalette.white
  }

  const theme = createTheme({
    palette,
    typography: {
      fontFamily: 'Inter, sans-serif',
      h2: {
        fontSize: '24px',
        fontWeight: '600',
        lineHeight: '32px'
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Inter, sans-serif';
          }
        `
      }
    }
  })

  return (
    <ThemeProvider
      theme={{
        ...theme,
        palette: {
          ...theme.palette
        }
      }}
    >
      {children}
    </ThemeProvider>
  )
}
