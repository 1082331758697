import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'
import { Styles } from '@percent/workplace-giving/types'
import { queryWidthLessThan } from '@percent/workplace-giving/utils/queryWidthLessThan'

export const getStyles = (customPalette: ColorPaletteType) => ({
  Container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  Header: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderColor: lemonadeTheme.colors.gray80,
    paddingBottom: '15px'
  },
  Title: {
    fontSize: lemonadeTheme.fontSizes.h6,
    color: lemonadeTheme.colors.black,
    fontWeight: '600'
  },
  Description: {
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    fontWeight: '400',
    color: lemonadeTheme.colors.gray800
  },
  FormContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: lemonadeTheme.sizes.s,

    '& > div:first-of-type > span': {
      '@media (min-width: 461px)': {
        display: 'inline-block',
        width: `calc(100% - 200px - 0.5rem)`,
        marginLeft: 'auto'
      }
    }
  },
  UploadText: {
    display: 'flex',
    marginTop: lemonadeTheme.sizes.xs,
    color: lemonadeTheme.colors.gray600,
    fontSize: lemonadeTheme.fontSizes.bodySmall
  },
  AmountInput: {
    boxSizing: 'border-box',
    width: '100%',
    height: lemonadeTheme.sizes[12],
    padding: lemonadeTheme.sizes[4],
    borderRadius: lemonadeTheme.borderRadii.medium,
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    border: '0.0625rem solid',
    borderColor: lemonadeTheme.colors.gray80,
    color: lemonadeTheme.colors.black,
    '::placeholder': {
      color: lemonadeTheme.colors.gray600
    },

    '&:hover': {
      borderColor: lemonadeTheme.colors.black
    },

    '&:focus': {
      borderColor: lemonadeTheme.colors.black
    },

    '&:active': {
      borderColor: lemonadeTheme.colors.black
    }
  },
  Select: {
    borderRadius: '0',
    '.MuiSelect-select': {
      width: '80px',
      padding: '0 !important',
      display: 'flex',
      alignItems: 'center'
    },
    '& .MuiSvgIcon-root': {
      display: 'none'
    },
    '&& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  Currency: {
    fontSize: lemonadeTheme.fontSizes.bodySmall,
    color: lemonadeTheme.colors.gray600,
    marginLeft: '5px'
  },
  ExitButton: {
    display: 'flex',
    minWidth: '28px',
    height: '28px',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    cursor: 'pointer',
    padding: 0,

    '&:hover': {
      backgroundColor: `${customPalette.primary100} !important`
    },

    '&:focus': {
      outline: `1px solid ${customPalette.primary}`
    }
  },
  SubmitButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap-reverse',
    gap: 2,
    marginTop: '16px',

    [queryWidthLessThan('xs')]: {
      gap: 1
    }
  },
  AddIconWrapper: {
    verticalAlign: 'middle',
    width: 24,
    height: 24
  },
  SelectedNonProfit: {
    padding: 2,
    background: lemonadeTheme.colors.gray70,
    border: '1px solid black',
    borderColor: lemonadeTheme.colors.gray90
  },
  ChangeButton: {
    textTransform: 'none'
  },
  CurrencyInputWrapper: {
    position: 'relative',
    width: '100%'
  },
  CurrencyInput: (status: 'danger' | 'default'): Styles => ({
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
    minWidth: '0px',
    padding: '0px',
    margin: '0px 0px 8px',
    border: '0px',
    verticalAlign: 'top',
    width: '100%',

    '& input': {
      boxSizing: 'border-Box',
      padding: `${lemonadeTheme.sizes.xs} ${lemonadeTheme.sizes.s}`,
      margin: '0px',
      lineHeight: '24px',
      outline: 'none',
      fontSize: '14px',

      color: lemonadeTheme.colors.black,

      '::placeholder': {
        color: lemonadeTheme.colors.gray600
      },

      borderRadius: '0.25rem',
      ...(status === 'default'
        ? {
            border: `0.0625rem solid ${lemonadeTheme.colors.gray80}`,

            '&:hover': {
              border: `0.0625rem solid ${lemonadeTheme.colors.black}`,
              outline: `${lemonadeTheme.sizes[1]} solid ${lemonadeTheme.colors.gray80}`
            },
            '&:focus, &:active': {
              border: `0.0625rem solid ${lemonadeTheme.colors.black}`
            }
          }
        : status === 'danger'
        ? {
            border: `0.0625rem solid ${lemonadeTheme.colors.alert400}`,

            '&:hover': {
              outline: `${lemonadeTheme.sizes[1]} solid ${lemonadeTheme.colors.alert100}`
            },
            '&:focus, &:active': {
              border: `0.0625rem solid ${lemonadeTheme.colors.alert400}`
            }
          }
        : {})
    }
  }),
  CurrencyWrapper: {
    position: 'absolute',
    top: '6px',
    right: '16px',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '16px',
    pointerEvents: 'none',

    '& span': {
      color: '#5A6256'
    },

    '& img': {
      margin: lemonadeTheme.sizes.xs
    }
  },
  OrganisationSearchWrapper: {
    display: 'flex',
    width: '100%',
    gap: '10px',
    '@media (max-width: 460px)': {
      flexDirection: 'column'
    }
  },
  CountrySelectWrapper: {
    width: 200,
    '@media (max-width: 460px)': {
      marginRight: '0px',
      width: '100%'
    }
  }
})
