import { FormatAmountProps } from './formatAmount.types'

export const formatAmount = ({
  currencyCode,
  value,
  language = 'en-us',
  fractionDigits = 2,
}: FormatAmountProps) =>
  new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currencyCode || 'USD', // some my nonprofits organisation have currencyCode: null
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(value)

export const formatMinorUnitsValueToFullNumberWithDecimals = (
  minorUnitsValue: number,
  minorUnits: number
): number => {
  return minorUnitsValue / 10 ** minorUnits
}

export const formatMajorToMinorUnits = (
  majorValue: number,
  minorUnits: number
): number => {
  return majorValue * 10 ** minorUnits
}
