import { useCallback } from 'react'
import { Alpha3Code } from 'i18n-iso-countries'
import { AxiosError } from 'axios'

import { useClient } from '../useClient/useClient'
import { useRateLimit } from '../useRateLimit/useRateLimit'

import { getAccountPartner } from '@percent/workplace-giving/api/auth/getAccountPartner/getAccountPartner'
import { getPermissions } from '@percent/workplace-giving/api/auth/getPermissions/getPermissions'
import { getGeolocationData } from '@percent/workplace-giving/api/geolocation/getGeolocationData'
import { Account } from '@percent/workplace-giving/context/auth/authContext/AuthContext.types'
import { getAccount } from '@percent/workplace-giving/api/auth/getAccount/getAccount'
import { AuthAction } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { getAccountTerms } from '@percent/workplace-giving/api/auth/getAccountTerms/getAccountTerms'
import countries from '@percent/workplace-giving/i18n/countries'
import { getAccountConfig } from '@percent/workplace-giving/api/account/accountConfig/accountConfig'
import {
  RATE_LIMIT_HIT,
  parseRetryAfter
} from '@percent/workplace-giving/context/client/clientContextController/interceptors/responseInterceptors'

export const useLogin = ({ dispatch }: { dispatch: (action: AuthAction) => void }) => {
  const { gsClient, client } = useClient()

  const { setRateLimitHit } = useRateLimit()

  const login = useCallback(async ({ account }: { account?: Account }) => {
    try {
      const [acc, accConfig, partner, permissions, acceptedTerms, geolocationData] = await Promise.all([
        account ?? getAccount(gsClient),
        getAccountConfig(gsClient),
        getAccountPartner(gsClient),
        getPermissions(gsClient),
        getAccountTerms(gsClient),
        getGeolocationData(client)
      ])

      dispatch({
        type: 'LOGIN',
        payload: {
          permissions,
          account: acc,
          partner,
          acceptedTerms,
          countryCode: (geolocationData?.countryCode ??
            countries.alpha2ToAlpha3(partner.currencyCode.substring(0, 2))) as Alpha3Code,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          accConfig,
          accountCountryCode: accConfig?.country ?? null
        }
      })
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 429) {
          setRateLimitHit({
            retryAfter: parseRetryAfter(e.response?.headers?.['Retry-After'])
          })

          return {
            rateLimitHit: true
          }
        }
      }

      if (e.message === RATE_LIMIT_HIT) {
        return {
          rateLimitHit: true
        }
      }
      throw e
    }

    return {}
  }, [])

  return {
    login
  }
}
