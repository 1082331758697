import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { SpeedInsights } from '@vercel/speed-insights/react'

import '@percent/workplace-giving/utils/dayjs/dayjs'
import { sendToVercelAnalytics, reportWebVitals } from '@percent/utility'
import { App } from '@percent/workplace-giving/app/app-entry'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <StrictMode>
    <App />
    <SpeedInsights />
  </StrictMode>
)

reportWebVitals(sendToVercelAnalytics)
