import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  NavBarWrapper: {
    zIndex: 100,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    boxShadow: '0px 0px 72px 0px rgba(7, 12, 24, 0.08)',
    backgroundColor: lemonadeTheme.colors.white
  },
  NavBarInnerWrapper: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingY: '26px',

    marginX: '64px',
    '@media(max-width: 899px)': {
      marginX: '24px'
    },

    '@media(min-width: 1568px)': {
      marginX: 'auto',
      maxWidth: '1440px'
    }
  },
  LogoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '28px',
    margin: 'auto auto auto 0',
    overflowY: 'hidden',
    width: '17%',

    a: {
      display: 'inline-block',
      width: '100%',
      lineHeight: 0,

      '&:focus': {
        outline: 'none'
      }
    },

    img: {
      maxHeight: '28px',
      width: 'auto',
      maxWidth: '100%'
    },

    '@media(max-width: 900px)': {
      display: 'none'
    }
  },
  NavBarLinksWrapper: {
    display: 'flex',
    flexFlow: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontSize: lemonadeTheme.fontSizes.bodySmall,

    '& a': {
      padding: '2px',
      marginRight: '18px',
      color: lemonadeTheme.colors.black,
      textDecoration: 'none',
      fontWeight: lemonadeTheme.fontWeights.medium,
      transition: 'color 250ms ease-in-out',
      textWrap: 'nowrap',

      '@media(max-width: 899px)': {
        marginRight: '8px'
      },

      '&.active': {
        color: customPalette.primary
      },

      '&:hover': {
        textDecoration: 'underline',
        background: 'none !important'
      },

      '&:focus:not(:focus-visible)': {
        outline: 'none'
      },

      '&:focus-visible': {
        outlineStyle: 'solid',
        color: customPalette.primary
      }
    }
  },
  MenuButton: {
    display: 'inline-flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
    textWrap: 'nowrap',
    padding: '2px',
    marginLeft: '18px',
    backgroundColor: 'transparent',
    border: 'none',
    gap: '4px',
    borderRadius: 0,
    color: lemonadeTheme.colors.black,
    fontWeight: lemonadeTheme.fontWeights.medium,
    textTransform: 'none',
    transition: 'color 250ms ease-in-out',
    '@media(max-width: 899px)': {
      marginLeft: '8px',
      minWidth: '24px'
    },

    '&::before': {
      position: 'absolute',
      left: '-18px',
      top: '50%',
      transform: 'translateY(-50%)',
      content: "''",
      width: '1px',
      height: '25px',
      backgroundColor: lemonadeTheme.colors.gray100,

      '@media(max-width: 899px)': {
        marginLeft: '12px'
      }
    },

    '&:hover': {
      textDecoration: 'underline',
      background: 'none'
    },

    '&:focus-visible': {
      outline: '1px dotted black'
    },

    '&:focus': {
      outlineStyle: 'solid',
      outlineColor: customPalette.primary
    }
  },
  MenuItem: {
    margin: `0 0 ${lemonadeTheme.sizes[1]} 0`,
    borderRadius: lemonadeTheme.borderRadii.small,
    fontSize: '0.875rem',
    fontWeight: lemonadeTheme.fontWeights.medium,

    '&:last-of-type': {
      marginBottom: 0
    },

    '& > svg': {
      marginRight: lemonadeTheme.sizes[2]
    }
  },
  Divider: {
    borderColor: customPalette.gray80,
    margin: `${lemonadeTheme.sizes[2]} 0`
  }
})
