import { Styles } from '@percent/workplace-giving/types'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const TagsWrapper: Styles = {
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  gap: lemonadeTheme.sizes[1],
  flexWrap: 'wrap',
  marginBottom: lemonadeTheme.sizes[8]
}
