import styled from 'styled-components'
import { styledVariant } from '../../../util'
import { inputStatusStyles } from '../../../shared/input-status'
import { SelectProps } from '../select.types'
import { AsyncSelectProps } from '../async-select.types'

export interface StyledSelectInputProps {
  $status?: SelectProps['status'] | AsyncSelectProps['status']
  $disabled?: boolean
}

export const StyledSelectTrigger = styled.div<StyledSelectInputProps>`
  ${styledVariant('$status', inputStatusStyles)};

  ${({ theme }) => `
        position: relative;
        box-sizing: border-box;
        height: ${theme.sizes[10]};
        padding: ${theme.sizes[4]};
        border-radius: ${theme.borderRadii.medium};
        background-color: white;
        display: flex;
        align-items: center;
        keyboard-focusable: true;
        color: ${theme.colors.gray600};
        transition: 0.2s all;
        cursor: pointer;

        &:hover {
            border-color: ${theme.colors.black};
        }

        input {
          flex-grow: 1;
          padding: 0;
          padding-right: 20px;
          border: none;
          background-color: transparent;

          &:focus {
            outline: none;
            border: none;
          }
          &:active {
            outline: none;
            border: none;
          }
          ::placeholder {
            font-size: ${theme.fontSizes.bodySmall};
            color: ${theme.colors.gray300};
          }
        }
    `}

  ${({ theme, $disabled }) =>
    $disabled &&
    `
        background-color: ${theme.colors.gray80};
        outline: none;
        border: none;
        cursor: not-allowed;

      &:hover {
        outline: none;
        border: none;
      }
      &:focus {
        outline: none;
      }
      &:active {
        outline: none;
        border: none;
      }
    `}
`
