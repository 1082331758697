import styled from 'styled-components'

export const StyledSelectAddOptionButton = styled.div`
  ${({ theme }) => `
    padding: ${theme.sizes[3]};
    color: ${theme.colors.gray900};
    display: flex;
    border-top: 1px solid ${theme.colors.gray80};

    &: hover {
      text-decoration: underline;
    }
  `}
`
