export const getCurrencySeparators = (locale: string) => {
  const separators = new Intl.NumberFormat(locale).formatToParts(10000.5)
  const group = separators.find((sep) => sep.type === 'group')
  const decimal = separators.find((sep) => sep.type === 'decimal')

  return {
    groupSeparator: group?.value,
    decimalSeparator: decimal?.value,
  }
}
