import { Money, sumMoney } from '../money/money'

export type MatchingType = 'multiply'

type CurrencyConversion = Readonly<{
  fxId: string
  rate: number
  source: Money
  target: Money
}>

type ConversionTarget = 'donation' | 'account' | 'partner'
type TargetConversions = Partial<Record<ConversionTarget, CurrencyConversion>>

type BaseMatching = {
  limit?: Money
  limitConversions?: TargetConversions
}

export type MultiplyMatching = BaseMatching & {
  kind: 'multiply'
  multiply: {
    factor: number
  }
}

export type Matching = MultiplyMatching

type CalculateMatchedAmountProps = {
  amount: Money
  matchingRules: Matching[]
}

const isDefined = <T>(v: T | undefined | null): v is T => !!v

const matchedAmount = (rule: Matching, baseValue: Money) => {
  const limit = [
    rule.limit,
    rule.limitConversions?.account?.target,
    rule.limitConversions?.donation?.target,
    rule.limitConversions?.partner?.target,
  ]
    .filter(isDefined)
    .find((v) => v.currency === baseValue.currency)

  const baseAmount = () => {
    switch (rule.kind) {
      case 'multiply':
        return {
          amount: baseValue.amount * rule.multiply.factor,
          currency: baseValue.currency,
        }
    }
  }

  const amount = baseAmount()
  if (limit && limit.amount < amount.amount) return limit
  return amount
}

export const calculateMatchedAmount = ({
  amount,
  matchingRules,
}: CalculateMatchedAmountProps) => {
  const totals = matchingRules.map((rule) => matchedAmount(rule, amount))
  return sumMoney({ amount: 0, currency: amount.currency }, ...totals)
}

export const totalFactor = (matchingRules: Matching[]) =>
  matchingRules
    .filter((rule) => rule.kind === 'multiply')
    .map((rule) => rule.multiply.factor)
    .reduce((acc, v) => acc + v, 0)
