import styled from 'styled-components'

export const StyledSelectTriggerDropdown = styled.div`
  ${({ theme }) => `
    margin-left: auto; 
    position: absolute;
    right: ${theme.sizes[2]};
    width: 2rem;
    height: 2rem;
  `}
`
