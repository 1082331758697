import { Box, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { NavLink, Link, useLocation, useNavigate } from 'react-router-dom-v6'
import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import { SignInCard } from '../SignInCard/SignInCard'
import { getStyles } from '../SignInCard/SignInCard.styles'

import { Button, FormField, Spinner, TextInput } from '@percent/lemonade'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { useAuth } from '@percent/workplace-giving/common/hooks'
import { emailLogin } from '@percent/workplace-giving/api/auth/emailLogin/emailLogin'
import { useMutation } from '@percent/workplace-giving/common/hooks/useMutation/useMutation'
import { setLoginType } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { useLogin } from '@percent/workplace-giving/common/hooks/useLogin/useLogin'
import { useSharedValidationRules } from '@percent/workplace-giving/common/hooks/useSharedValidationRules/useSharedValidationRules'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

const REQUIRED_2FA_LOGIN = 'auth/requires_two_fa_login'

type LocationProps = {
  from: Location
}

export function EmailSignIn() {
  const { t } = useTranslation()
  const location = useLocation().state as LocationProps
  const navigate = useNavigate()
  const { dispatch } = useAuth()
  const { login } = useLogin({ dispatch })
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const queryClient = useQueryClient()
  const { validateEmail, validateString } = useSharedValidationRules()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  const { mutateAsync } = useMutation({
    mutationFn: emailLogin,
    onSuccess: async res => {
      if (res.result === 'success') {
        setLoginType('ep')
        try {
          await login({ account: res.data.data })
          const { from } = location
          queryClient.removeQueries()
          navigate((from.pathname ?? AppRoute.HOME) + (from.search ?? ''), { replace: true, state: location })
        } catch (e: any) {
          setError(t('workplace_giving.errors.unexpected'))
        }
      } else {
        setError(t('workplace_giving.errors.invalidCredentials'))
      }
      setLoading(false)
    },
    onError: (e: any) => {
      if (e.response.status === 400 && e.response.data.error.code === REQUIRED_2FA_LOGIN) {
        navigate('/sign-in/2fa', {
          state: {
            loginAttempted: true
          }
        })

        return
      }
      setError(t('workplace_giving.errors.unexpected'))
    }
  })

  const { errors, values, handleChange, handleSubmit, handleBlur, touched } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: () =>
      Yup.object().shape({
        email: validateEmail,
        password: validateString()
      }),
    onSubmit: async ({ email, password }) => {
      setLoading(true)
      setError('')

      try {
        await mutateAsync({ email, password })
      } catch (e) {
        setError(t('workplace_giving.errors.unexpected'))
        setLoading(false)
      }
    }
  })

  useEffect(() => {
    setError('')
  }, [values.email, values.password, setError])

  return (
    <SignInCard title={t('workplace_giving.login.signIn')}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Spinner size="l" />
        </Box>
      ) : (
        <Box component="form" sx={Styles.Form} onSubmit={handleSubmit}>
          <FormField
            label={t('workplace_giving.login.email.label')}
            status={touched.email && errors.email ? 'danger' : 'default'}
            statusMessage={errors.email}
            data-testid="email"
            necessity="required"
          >
            <TextInput
              name="email"
              value={values.email}
              placeholder={t('workplace_giving.login.email.placeholder')}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormField>
          <FormField
            label={t('workplace_giving.login.password.label')}
            status={touched.password && errors.password ? 'danger' : 'default'}
            statusMessage={errors.password}
            data-testid="password"
            necessity="required"
          >
            <TextInput
              type="password"
              name="password"
              value={values.password}
              placeholder={t('workplace_giving.login.password.placeholder')}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormField>
          {error ? (
            <Typography variant="body2" sx={Styles.ErrorMessage}>
              {error}
            </Typography>
          ) : null}
          <Button variant="primary" type="submit">
            {t('workplace_giving.login.signIn')}
          </Button>
          <Box sx={Styles.LinksWrapper({ centered: false })}>
            <NavLink to={AppRoute.FORGOT_PASSWORD}>{t('workplace_giving.login.forgotPassword')}</NavLink>
            <Link state={location} to={AppRoute.SSO_SIGN_IN}>
              {t('workplace_giving.login.signInWithSSO')}
            </Link>
          </Box>
        </Box>
      )}
    </SignInCard>
  )
}
