import { PropsWithChildren } from 'react'
import { BrowserRouter as Router } from 'react-router-dom-v6'
import { I18nextProvider } from 'react-i18next'
import { MantineProvider } from '@mantine/core'
import { QueryParamProvider } from 'use-query-params'

import { AuthContextController } from '../context/auth/authContextController/AuthContextController'
import { RateLimitContextController } from '../context/rateLimit/rateLimitContextController/RateLimitContextController'
import { MatchRequestFormContextController } from '../context/matchRequestForm/matchRequestFormContextController/MatchRequestFormContextController'
import { useColorTheme } from '../common/hooks/useColorTheme/useColorTheme'

import { ClientContextController } from '@percent/workplace-giving/context/client/clientContextController/ClientContextController'
import i18n from '@percent/workplace-giving/i18n/config'
import { ThemeContext } from './themeProvider/ThemeProvider'
import { LemonadeProvider } from '@percent/lemonade'
import { LoggerContextController } from '@percent/workplace-giving/context/logger/LoggerContextController/LoggerContextController'
import { SplitSdkProvider } from './SplitSdkProvider'
import { AnalyticsContextController } from '@percent/workplace-giving/context/analytics/analytics/AnalyticsContextController/AnalyticsContextController'
import { ReactRouter6Adapter } from '@percent/workplace-giving/utils/queryParamsAdapter'

export function AppProviders({ children }: PropsWithChildren) {
  const { theme } = useColorTheme()

  return (
    <LoggerContextController>
      <MantineProvider withStaticClasses>
        <ThemeContext>
          <LemonadeProvider customPalette={theme}>
            <I18nextProvider i18n={i18n}>
              <ClientContextController>
                <RateLimitContextController>
                  <AnalyticsContextController>
                    <AuthContextController>
                      <SplitSdkProvider>
                        <MatchRequestFormContextController>
                          <Router>
                            <QueryParamProvider adapter={ReactRouter6Adapter}>{children}</QueryParamProvider>
                          </Router>
                        </MatchRequestFormContextController>
                      </SplitSdkProvider>
                    </AuthContextController>
                  </AnalyticsContextController>
                </RateLimitContextController>
              </ClientContextController>
            </I18nextProvider>
          </LemonadeProvider>
        </ThemeContext>
      </MantineProvider>
    </LoggerContextController>
  )
}
