import { theme as lemonadeTheme } from '@percent/lemonade'
import { hexToRgba } from '@percent/workplace-giving/utils/hexToRgba'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  WalletAmountContainer: {
    zIndex: 20,
    display: 'flex',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: `${lemonadeTheme.sizes[2]} ${lemonadeTheme.sizes[3]}`,
    backgroundColor: lemonadeTheme.colors.white,
    borderRadius: lemonadeTheme.sizes[6],
    gap: lemonadeTheme.sizes[1],
    fontSize: lemonadeTheme.sizes[5],
    fontWeight: lemonadeTheme.fontWeights.semiBold,

    '& img': {
      width: lemonadeTheme.sizes[5]
    }
  },
  WalletGiftHeader: {
    overflow: 'hidden',
    position: 'relative',
    marginBottom: lemonadeTheme.sizes[6],
    height: '145px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    background: `radial-gradient(95.26% 102.84% at 83.75% 35.42%, ${hexToRgba(
      customPalette.primary,
      '0.1'
    )} 0%, ${hexToRgba(customPalette.primary, '0')} 100%), #FFF;`,
    borderRadius: lemonadeTheme.borderRadii.large,

    '&::before': {
      content: "''",
      display: 'block',
      width: '100%',
      height: '100%',
      border: '1px solid #E9E8EB',
      borderRadius: lemonadeTheme.borderRadii.large
    }
  },
  WalletGiftHeaderStructure: {
    position: 'absolute',
    left: '100%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexFlow: 'column',
    gap: lemonadeTheme.sizes[2],

    '&:first-of-type': {
      left: 0,
      right: 'auto',
      transform: 'translate(-50%, -50%)'
    },

    '& span': {
      display: 'inline-block',
      boxSizing: 'border-box',
      width: lemonadeTheme.sizes[4],
      height: lemonadeTheme.sizes[4],
      minWidth: lemonadeTheme.sizes[4],
      minHeight: lemonadeTheme.sizes[4],
      backgroundColor: lemonadeTheme.colors.white,
      borderRadius: '100%',
      border: '1px solid #E9E8EB'
    }
  }
})
