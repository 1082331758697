import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  PlusButton: {
    display: 'inline-flex',
    backgroundColor: customPalette.primary,
    width: lemonadeTheme.sizes[8],
    height: lemonadeTheme.sizes[8],
    minWidth: lemonadeTheme.sizes[8],
    minHeight: lemonadeTheme.sizes[8],
    borderRadius: '100%',
    marginRight: '18px',

    '&:hover, &:active': {
      backgroundColor: customPalette.primary600
    }
  }
})
