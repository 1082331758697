import { Styles } from '@percent/workplace-giving/types'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const Card: Styles = {
  padding: lemonadeTheme.sizes.m,
  borderRadius: '8px',
  boxShadow: '0px 2px 16px 0px #0D0D0D14',
  display: 'flex',
  flexDirection: 'column',
  gap: lemonadeTheme.sizes.s,
  flexFlow: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexWrap: 'nowrap'
}
export const CardContent: Styles = {
  marginRight: '8px'
}
