export const createShortLink = (url: string, withPath = false) => {
  try {
    const parsedUrl = new URL(url)

    if (withPath) {
      return parsedUrl.hostname + parsedUrl.pathname
    }

    return parsedUrl.hostname
  } catch (_) {
    return url
  }
}
