import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Color } from '../../theme';
import {
  StrenghtMeterProps,
  StyledStrenghtBarProps,
} from './strenght-meter.types';

const variant: { message: string; color: Color }[] = [
  {
    message: '😱 Very weak',
    color: 'alert400',
  },
  {
    message: '😨 Weak',
    color: 'alert400',
  },
  {
    message: '😶 Medium',
    color: 'warning400',
  },
  {
    message: '💪 Strong',
    color: 'success400',
  },
  {
    message: '🚀 Very strong',
    color: 'success400',
  },
];

export const StyledStrenghtMeter = styled.div`
  ${({ theme }) =>
    `
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: ${theme.sizes[2]};
    `}
`;

export const StyledStrenghtBar = styled.div<StyledStrenghtBarProps>`
  ${({ theme, $passwordStrength }) =>
    `
    width: 100%;
    height: ${theme.sizes[1]};
    background:  ${theme.colors.gray80};
    border-radius: ${theme.borderRadii.large};

    & div {
      width: ${($passwordStrength + 1) * 20}%;
      height: inherit;
      transition: 200ms;
      border-radius: ${theme.borderRadii.large};
      background: ${theme.colors[variant[$passwordStrength].color]};
    }
    `}
`;

const getPasswordStrength = (password: string) => {
  let strengthMeter = 0;

  const hasNumber = (password: string) => /[0-9]/.test(password);
  const hasMixed = (password: string) =>
    /[a-z]/.test(password) && /[A-Z]/.test(password);
  const hasSpecial = (password: string) => /[^A-Za-z0-9]/.test(password);
  const hasRepeated = (password: string) => /(.)\1/g.test(password);

  if (password.length < 8) {
    return 0;
  }

  if (password.length > 7) {
    strengthMeter += 1;
  }

  if (hasNumber(password)) {
    strengthMeter += 1;
  }

  if (hasSpecial(password)) {
    strengthMeter += 1;
  }

  if (hasMixed(password)) {
    strengthMeter += 1;
  }

  if (hasRepeated(password)) {
    strengthMeter -= 1;
  }

  return strengthMeter;
};

export const StrenghtMeter = ({ text }: StrenghtMeterProps) => {
  const [passwordStrength, setPasswordStrength] = useState(
    getPasswordStrength(text)
  );

  useEffect(() => {
    setPasswordStrength(getPasswordStrength(text));
  }, [text]);

  return (
    <StyledStrenghtMeter>
      <StyledStrenghtBar $passwordStrength={passwordStrength}>
        <div />
      </StyledStrenghtBar>
      {variant[passwordStrength].message}
    </StyledStrenghtMeter>
  );
};
