import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { emailRegex, isValidWebsiteURL } from '@percent/utility'

export const DEFAULT_MAX_STRING_LENGTH = 255
export const DEFAULT_URL_MAX_LENGTH = 2048

export const checkIfDateIsInTheFuture = (date: Date) => dayjs(date).isAfter(dayjs())

export const checkIfEndDateIsAfterStart = (start: Date, end: Date) => dayjs(end).isAfter(dayjs(start))

export function useSharedValidationRules() {
  const { t } = useTranslation()

  const validateString = ({
    maxLength = DEFAULT_MAX_STRING_LENGTH,
    optional = false
  }: {
    maxLength?: number
    optional?: boolean
  } = {}) =>
    Yup.string()
      .trim()
      .when([], {
        is: () => !!maxLength,
        then: schema =>
          schema.max(
            maxLength || DEFAULT_MAX_STRING_LENGTH,
            t('workplace_giving.validation.maxCharacters', {
              max: maxLength || DEFAULT_MAX_STRING_LENGTH
            })
          )
      })
      .when([], {
        is: () => !optional,
        then: Yup.string().trim().required(t('workplace_giving.validation.requiredField'))
      })

  const validateDate = ({
    shouldBeInTheFuture = false,
    optional = false
  }: {
    shouldBeInTheFuture?: boolean
    optional?: boolean
  } = {}) =>
    Yup.date()
      .nullable()
      .when([], {
        is: () => shouldBeInTheFuture,
        then: schema =>
          schema.test('startDateInTheFuture', t('workplace_giving.validation.dateInTheFuture'), value => {
            if (value) {
              return checkIfDateIsInTheFuture(value)
            }

            return true
          })
      })
      .when([], {
        is: () => !optional,
        then: Yup.date().required(t('workplace_giving.validation.requiredField'))
      })

  const validateEmail = Yup.string()
    .required(t('workplace_giving.validation.requiredField'))
    .matches(emailRegex, t('workplace_giving.login.email.invalidEmailError'))
    .max(255, t('workplace_giving.validation.maxCharacters', { max: 255 }))

  const validateUrl = (optional = false) =>
    isValidWebsiteURL(t('workplace_giving.validation.url'))
      .trim()
      .max(DEFAULT_URL_MAX_LENGTH, t('workplace_giving.validation.maxCharacters', { max: DEFAULT_URL_MAX_LENGTH }))
      .when([], {
        is: () => !optional,
        then: schema => schema.required(t('workplace_giving.validation.requiredField'))
      })

  const validateNumber = ({
    min,
    max,
    mustBePositive = true,
    optional = false,
    canEqualMax = false
  }: {
    min?: number
    max?: number
    mustBePositive?: boolean
    optional?: boolean
    canEqualMax?: boolean
  } = {}) =>
    Yup.number()
      .nullable()
      .when([], {
        is: () => mustBePositive,
        then: schema => schema.positive(t('workplace_giving.validation.positive'))
      })
      .when([], {
        is: () => typeof min === 'number',
        then: schema => schema.min(min!, t('workplace_giving.validation.minAmount', { min }))
      })
      .when([], {
        is: () => typeof max === 'number',
        then: schema =>
          schema.max(
            max!,
            canEqualMax
              ? t('workplace_giving.validation.maxOrEqualAmount', { max })
              : t('workplace_giving.validation.maxAmount', { max })
          )
      })
      .when([], {
        is: () => !optional,
        then: schema => schema.required(t('workplace_giving.validation.requiredField'))
      })

  return {
    validateString,
    validateDate,
    validateEmail,
    validateUrl,
    validateNumber
  }
}
