import React from 'react'
import styled from 'styled-components'
import DOMPurify, { sanitize } from 'isomorphic-dompurify'

import { RichDescriptionProps } from './rich-description.types'

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    node.setAttribute('target', '_blank')
    node.setAttribute('rel', 'noopener')
  }
  if ('href' in node) {
    const url = node.getAttribute('href')
    if (url && !url.match(/^(http:\/\/|https:\/\/|ftp:\/\/)/)) {
      node.setAttribute('href', `https://${url}`)
    }
  }
})

export const StyledRichDescription = styled.div`
  ${({ theme }) => `
    & > * {
      box-sizing: border-box;
      margin-top: ${theme.sizes[4]};
      color: ${theme.colors.black}; 
    };
    & > *:not(&:last-child) {
      margin-bottom: ${theme.sizes[4]};
    };
    & > *:last-child {
      margin-bottom: 0 !important;
    };
    h1, h2, h3, h4, h5, h6, b {
      font-weight: ${theme.fontWeights.semiBold};
    };
    h1 {
      margin-top: 0;
      font-size: ${theme.sizes[10]};
      line-height: ${theme.sizes[12]};
    };
    h2 {
      font-size: ${theme.sizes[6]};
      line-height: ${theme.sizes[8]}; 
    };
    h3 {
      font-size: ${theme.sizes[5]};
      line-height: ${theme.sizes[6]};
    };
    p, blockquote, li, dt, dl, a {
      font-size: ${theme.fontSizes.body};
      line-height: ${theme.sizes[6]};
    };
    mark {
      background-color: ${theme.colors.primary100};  
    };
    a {
      color: ${theme.colors.primary};
      transition: color ${theme.transitionTime} ease;

      &:hover {
        color: ${theme.colors.primaryHover};
      }
    };
    blockquote {
      margin-left: 0;
      margin-right: 0;
      padding-left: ${theme.sizes[4]};
      border-left: 3px solid ${theme.colors.primary};;
    };
    ul, ol {
      padding-left: ${theme.sizes[10]};
    };
    dl {
      padding-left: 0;
    };
    dt {
      font-style: normal;
      margin-top: ${theme.sizes[3]};
      font-weight: ${theme.fontWeights.medium};
    };
    dd {
      margin-left: ${theme.sizes[4]};
      color: ${theme.colors.gray800};
    };
    li {
      margin-bottom: ${theme.sizes[2]};
    };
    strong {
      font-weight: ${theme.fontWeights.semiBold};
    };
  `}
`

export const RichDescription = ({
  content,
  sanitizationConfig,
  testId,
}: RichDescriptionProps) => (
  <StyledRichDescription
    data-testid={testId}
    dangerouslySetInnerHTML={{
      __html: sanitize(content, { ...sanitizationConfig }) as string,
    }}
  />
)
