type User = {
  id: string
  email: string | undefined
}

export const getSplitKeyForUser = ({ id, email }: User): string => {
  const internalDomain = 'poweredbypercent.com'
  if (!email) return id

  return email.endsWith(internalDomain) ? email : id
}
