import styled from 'styled-components'
import { Badge } from '../badge'
import { OptionGroupProps, OptionProps } from './option.types'
import { FakeCheckbox } from '../fake-checkbox'

export const StyledOption = styled.div`
  ${({ theme }) => `
    padding: ${theme.sizes[2]} ${theme.sizes[3]};
    border-left: 0.125rem solid transparent;
    cursor: pointer;

    &.hover, &:hover {
        border-color: ${theme.colors.primary400};
        background-color: ${theme.colors.gray70};
    }
  `}
`

export const StyledOptionText = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.gray900};
    margin-right: ${theme.sizes[2]};
  `}
`

export const StyledOptionDescription = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.gray600};
    font-size: ${theme.fontSizes.bodySmall};
  `}
`

export const OptionComponent: React.FC<OptionProps> = ({
  badge,
  label,
  prefix: icon,
  description,
  multiSelect,
  isSelected = false,
}) => (
  <>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {multiSelect && (
        <div style={{ marginTop: 5, marginRight: '0.5rem' }}>
          <FakeCheckbox
            isSelected={isSelected}
            selectedTestId={`${label}-fake-checkbox-selected`}
          />
        </div>
      )}

      {icon ? (
        <div
          style={{ marginRight: '0.5rem', maxHeight: '2rem', display: 'flex' }}
        >
          {icon}
        </div>
      ) : null}
      <StyledOptionText>{label}</StyledOptionText>
      {badge ? (
        <div>{<Badge variant="default">{badge.text}</Badge>}</div>
      ) : null}
    </div>
    <StyledOptionDescription>{description}</StyledOptionDescription>
  </>
)

export const Option: React.FC<OptionProps> = (props) => {
  const { onClick, onMouseEnter, isActive, ...componentProps } = props
  return (
    <StyledOption
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      className={isActive ? 'hover' : ''}
    >
      <OptionComponent {...componentProps} />
      <option value={props.value} hidden></option>
    </StyledOption>
  )
}

export const StyledOptionGroupTitle = styled.div`
  ${({ theme }) => `
    padding: ${theme.sizes[4]} ${theme.sizes[3]};
    text-transform: uppercase;
    font-weight: ${theme.fontWeights.bold};
    font-size: ${theme.fontSizes.bodySmall};
    color: ${theme.colors.gray500};
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  `}
`

export const OptionGroup: React.FC<OptionGroupProps> = ({
  title,
  children,
}) => {
  return (
    <>
      <StyledOptionGroupTitle>{title}</StyledOptionGroupTitle>
      {children}
    </>
  )
}
