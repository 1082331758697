import { Styles } from '@percent/workplace-giving/types'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const Container: Styles = {
  maxWidth: '100%',
  width: '100%',
  boxSizing: 'border-box',
  backgroundColor: lemonadeTheme.colors.white,
  overflow: 'hidden',

  '@media (min-width: 768px)': {
    borderRadius: '8px'
  },

  '& > *': {
    fontFamily: 'Inter, sans-serif !important'
  }
}

export const ContentWrapper: Styles = {
  boxSizing: 'border-box',
  padding: '24px',
  overflowY: 'auto',
  height: '100vh',

  '@media (min-width: 768px)': {
    height: 'auto',
    maxHeight: '92vh',
    padding: '16px 20px'
  }
}
