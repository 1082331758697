import { css, CSSProp } from 'styled-components';
import { Theme } from '../theme';

export type InputStatus = 'default' | 'danger';

export const inputStatusStyles: Record<
  InputStatus,
  (obj: { theme: Theme }) => CSSProp
> = {
  default: ({ theme }) => css`
    border: 0.0625rem solid ${theme.colors.gray80};

    &:hover {
      border: 0.0625rem solid ${theme.colors.black};
      outline: ${theme.sizes[1]} solid ${theme.colors.gray80};
    }
    &:focus,
    &:active {
      border: 0.0625rem solid ${theme.colors.black};
    }
  `,
  danger: ({ theme }) => css`
    border: 0.0625rem solid ${theme.colors.alert400};

    &:hover {
      outline: ${theme.sizes[1]} solid ${theme.colors.alert100};
    }
    &:focus,
    &:active {
      border: 0.0625rem solid ${theme.colors.alert400};
    }
  `,
};
