import { Box } from '@mui/material'
import { useEffect } from 'react'
import { useMediaQuery } from 'usehooks-ts'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom-v6'

import * as Styles from './DonationMatchRequestModal.styles'
import { RequestSubmitForm } from '@percent/workplace-giving/common/components/DonationMatchRequest/RequestSubmitForm/RequestSubmitForm'
import { createDonationMatchRequest } from '@percent/workplace-giving/api/donation-match-request/create/create-donation-match-request'
import { RequestSubmitSuccess } from '@percent/workplace-giving/common/components/DonationMatchRequest/RequestSubmitSuccess/RequestSubmitSuccess'
import { Modal, useToast } from '@percent/lemonade'
import { useAuth, useWindowDimensions, useMatchRequestForm } from '@percent/workplace-giving/common/hooks'
import {
  getAccountConfigFromAuthState,
  getPartnerFromAuthState
} from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { useAnalytics } from '@percent/workplace-giving/common/hooks/useAnalytics/useAnalytics'
import { DonationMatchRequest } from '@percent/workplace-giving/api/donation-match-request/create/create-donation-match-request.types'

export function DonationMatchRequestModal() {
  const { state: modalState, dispatch } = useMatchRequestForm()
  const { t } = useTranslation()
  const { state } = useAuth()
  const { track } = useAnalytics()
  const partner = getPartnerFromAuthState(state)!
  const isDesktop = useMediaQuery('(min-width: 768px)')
  const { width: windowWidth } = useWindowDimensions()
  const { addToast } = useToast()
  const accountConfig = getAccountConfigFromAuthState(state)
  const navigate = useNavigate()

  useEffect(() => {
    if (modalState.isOpen) {
      document.body.setAttribute('style', `overflow-y:hidden`)
    }
  }, [modalState.isOpen])

  const onClose = () => {
    document.body.setAttribute('style', 'overflow-y:auto')
    dispatch({ type: 'CLOSE' })
  }

  const onComplete = (params: DonationMatchRequest) => {
    track({
      event: 'Donation Match Request Completed',
      properties: {
        currency: params.amount.currency,
        amount: params.amount.amount
      }
    })
    dispatch({ type: 'SUCCESS' })
  }

  const onSuccess = () => {
    document.body.setAttribute('style', 'overflow-y:auto')
    dispatch({ type: 'CLOSE' })
    navigate(AppRoute.MY_IMPACT, { state: { activeTab: 'off-platform' } })
  }

  return (
    <Modal open={modalState.isOpen} onClose={onClose} isFullscreen={!isDesktop} width={isDesktop ? 504 : windowWidth}>
      <Box sx={Styles.Container}>
        <Box sx={Styles.ContentWrapper}>
          {!modalState.isSuccess ? (
            <RequestSubmitForm
              currency={accountConfig?.currency ?? partner.currencyCode}
              apiFunc={createDonationMatchRequest}
              onComplete={onComplete}
              handleClose={onClose}
            />
          ) : (
            <RequestSubmitSuccess
              handleClose={() => {
                addToast(t('workplace_giving.donationMatchRequest.success.toast'), 'success')
                onSuccess()
              }}
            />
          )}
        </Box>
      </Box>
    </Modal>
  )
}
