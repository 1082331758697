import styled from 'styled-components'

export const StyledSelectOptions = styled.div`
  ${({ theme }) => `
    position: relative;
    overflow-y: auto;
    max-height: 20rem;
    height: 100%;
    transition: 0.2s all;
  `}
`
