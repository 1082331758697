import styled from 'styled-components'

export interface StyledSearchableProps {
  $disabled?: boolean
}

export const StyledSearchable = styled.div<StyledSearchableProps>`
  ${({ theme, $disabled }) => `
      pointer-events: ${$disabled ? 'none' : 'auto'};
      width: 100%;
      text-align: left;
      color: ${theme.colors.black};
      margin-right: ${theme.sizes[6]};
      display: flex;
      align-items: center;

      label {
        display: flex;
      }
      input {
        pointer-events: ${$disabled ? 'none' : 'auto'};
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        min-width: 0;

        ::placeholder {
          color: ${theme.colors.gray300}
        }
      }
    `}
`
