import styled from 'styled-components'
import { FlagAvatarProps } from './flag-avatar.types'
import { alpha3ToAlpha2 } from 'i18n-iso-countries'
import { ReactCountryFlag } from 'react-country-flag'

const StyledFlag = styled(ReactCountryFlag)`
  width: 24px;
  border-radius: 2px;
  border: ${({ theme }) => `1px solid ${theme.colors.gray80};`},
  object-fit: contain;
`

export function FlagAvatar({ code3, code, ...props }: FlagAvatarProps) {
  const alpha2code = code3 ? alpha3ToAlpha2(code3) : code

  return (
    <StyledFlag
      countryCode={alpha2code}
      alt={alpha2code}
      svg
      aria-label={alpha2code}
      style={{
        width: '24px',
        height: 'auto',
      }}
      {...props}
    />
  )
}
