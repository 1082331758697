import { useTranslation } from 'react-i18next'

const CONTACT_US_EMAIL = 'contact@goodstack.io'

export function InsufficientPermissions() {
  const { t } = useTranslation()

  return (
    <div>
      <h1>{t('workplace_giving.insufficientPermissions.title')}</h1>
      <p>{t('workplace_giving.insufficientPermissions.text')}</p>
      <a data-testid="contact-us" href={`mailto: ${CONTACT_US_EMAIL}`}>
        {t('workplace_giving.insufficientPermissions.reachOut')}
      </a>
    </div>
  )
}
