import { PropsWithChildren } from 'react'

import { useSpring, animated } from 'react-spring'
import {
  AnimatedContainerProps,
  AnimationType,
} from './animated-container.types'

const animations: Record<AnimationType, object> = {
  scaleInOut: {
    loop: true,
    from: { transform: 'scale(1)' },
    to: [{ transform: 'scale(1.2)' }, { transform: 'scale(1)' }],
    config: { tension: 60, friction: 30 },
  },
}

export const AnimatedContainer = ({
  animation = AnimationType.scaleInOut,
  children,
}: PropsWithChildren<AnimatedContainerProps>) => {
  const animationProps = useSpring(animations[animation])

  return <animated.div style={animationProps}>{children}</animated.div>
}
