import styled from 'styled-components'

export const StyledSelectList = styled.div<{ $orientation: 'up' | 'down' }>`
  ${({ theme, $orientation }) => `
    position: absolute;
    top: ${
      $orientation === 'down' ? 'calc(100% + ' + theme.sizes[2] + ')' : 'auto'
    };
    bottom: ${
      $orientation === 'up' ? 'calc(100% + ' + theme.sizes[2] + ')' : 'auto'
    };
    left: 0;
    border-radius: ${theme.borderRadii.medium};
    overflow: hidden;
    box-shadow: ${theme.boxShadows.medium};
    transition: 0.2s all;
    width: 100%;
    z-index: ${theme.zIndex.select};
    text-align: left;
    background-color: white;
  `}
`
