import { Box, Card, Typography } from '@mui/material'

import { SignInCardProps } from './SignInCard.types'
import { getStyles } from './SignInCard.styles'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

export function SignInCard({ title, description, children }: SignInCardProps) {
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  return (
    <Box sx={Styles.Wrapper}>
      <Card elevation={0} sx={Styles.Card}>
        <Typography variant="h2" component="h2" sx={Styles.Title}>
          {title}
        </Typography>
        {description ? (
          <Typography sx={Styles.Description} variant="body2">
            {description}
          </Typography>
        ) : null}
        <Box sx={Styles.ContentWrapper}>{children}</Box>
      </Card>
    </Box>
  )
}
