import { Box } from '@mui/material'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { NavLink, useLocation } from 'react-router-dom-v6'
import { useState } from 'react'

import { SignInCard } from '../SignInCard/SignInCard'
import { getStyles } from '../SignInCard/SignInCard.styles'

import { Button, FormField, Spinner, TextInput } from '@percent/lemonade'
import { AppRoute } from '@percent/workplace-giving/routing/AppRoute.enum'
import { useMutation } from '@percent/workplace-giving/common/hooks/useMutation/useMutation'
import { ssoLogin } from '@percent/workplace-giving/api/auth/ssoLogin/ssoLogin'
import { config } from '@percent/workplace-giving/config/config'
import { useSharedValidationRules } from '@percent/workplace-giving/common/hooks/useSharedValidationRules/useSharedValidationRules'
import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'

type LocationProps = {
  from: Location
}

export function SSOSignIn() {
  const { t } = useTranslation()
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)
  const locationState = useLocation().state as LocationProps
  const { validateEmail } = useSharedValidationRules()

  const [errorMessage, setErrorMessage] = useState('')
  const [awaitingRedirect, setAwaitingRedirect] = useState(false)

  const { mutateAsync } = useMutation({
    mutationFn: ssoLogin,
    onSuccess: res => {
      if (res.result === 'error') {
        setErrorMessage(t('workplace_giving.login.signInWithSSOErrors.partnerNotConfigured'))
        setAwaitingRedirect(false)

        return
      }
      window.location.href = res.data.loginUrl
    },
    onError: () => {
      setErrorMessage(t('workplace_giving.errors.unexpected'))
      setAwaitingRedirect(false)
    }
  })

  const { errors, values, handleChange, handleSubmit, handleBlur, touched } = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: () =>
      Yup.object().shape({
        email: validateEmail
      }),
    onSubmit: ({ email }) => {
      setAwaitingRedirect(true)
      let redirectUrl

      if (locationState) {
        const { from } = locationState
        redirectUrl = config.urls.appUrl + (from.pathname ?? AppRoute.HOME) + (from.search ?? '')
      }

      return mutateAsync({ email, redirectUrl })
    }
  })

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('')
    handleChange(event)
  }

  return (
    <SignInCard title={t('workplace_giving.login.signInWithSSO')}>
      {awaitingRedirect ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Spinner size="l" />
        </Box>
      ) : (
        <Box component="form" sx={Styles.Form} onSubmit={handleSubmit}>
          <FormField
            label={t('workplace_giving.login.email.label')}
            status={errorMessage ? 'danger' : touched.email && errors.email ? 'danger' : 'default'}
            statusMessage={errors.email || errorMessage}
            data-testid="email"
            necessity="required"
          >
            <TextInput
              name="email"
              value={values.email}
              placeholder={t('workplace_giving.login.email.placeholder')}
              onBlur={handleBlur}
              onChange={handleInputChange}
            />
          </FormField>

          <Button variant="primary" type="submit" disabled={awaitingRedirect}>
            {t('workplace_giving.login.signInWithSSO')}
          </Button>

          <Box sx={Styles.LinksWrapper({ centered: true })}>
            <NavLink to={AppRoute.SIGN_IN}>{t('workplace_giving.login.signInWithEmail')}</NavLink>
          </Box>
        </Box>
      )}
    </SignInCard>
  )
}
