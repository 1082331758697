import { createContext } from 'react'

import { ColorPaletteType } from '../colorThemeContextController/ColorThemeContextController'

type ColorThemeContext = {
  theme: ColorPaletteType
  setTheme: (theme: ColorPaletteType) => void
}

export const ColorThemeContext = createContext<ColorThemeContext | undefined>(undefined)
