export const enum IamClient {
  InternalApp = 'InternalApp',
  CauseApp = 'CauseApp',
  PartnerApp = 'PartnerApp',
}

const appsWithAuthentication = [
  'causeDashboard',
  'partnerDashboard',
  'workplaceGiving',
  'adminDashboard',
] as const

export type NameOfIamAppWithAuthentication =
  typeof appsWithAuthentication[number]

export const iamClientApps: Record<NameOfIamAppWithAuthentication, IamClient> =
  {
    adminDashboard: IamClient.InternalApp,
    partnerDashboard: IamClient.PartnerApp,
    workplaceGiving: IamClient.PartnerApp,
    causeDashboard: IamClient.CauseApp,
  }

export const getLoginHeadersForApp = (app: NameOfIamAppWithAuthentication) => {
  return {
    'x-pbp-client': iamClientApps[app],
  }
}
