import React from 'react'
import styled from 'styled-components'
import {
  CheckboxProps,
  CheckboxVariant,
  StyledCheckboxHelperProps,
  StyledCheckboxLabelTextProps,
  StyledCheckboxProps,
} from './checkbox.types'
import { Icon } from '../icon'

const StyledCheckboxContainer = styled.div`
  ${({ theme }) =>
    `
    display: flex;
    flex-flow: column;
  `}
`

const StyledCheckboxWrapper = styled.div`
  ${({ theme }) =>
    `
    position: relative;
    display: flex;
    justify-content: center;
    width: ${theme.sizes[5]};
    height: ${theme.sizes[5]};
    margin-right: ${theme.sizes[3]};

    &  > svg {
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `}
`

const StyledCheckboxLabel = styled.label`
  ${({ theme }) =>
    `
    display: inline-flex;
    justify-content: start;
    align-items: start;
    margin-right: auto;
  `}
`

const StyledCheckboxLabelText = styled.span<StyledCheckboxLabelTextProps>`
  ${({ theme, $isDisabled, $necessity }) =>
    `
    cursor: ${$isDisabled ? 'default' : 'pointer'};
    font-weight: ${theme.fontWeights.medium};
    font-size: ${theme.fontSizes.bodySmall};
    line-height: ${theme.sizes[5]};
    color: ${$isDisabled ? theme.colors.gray600 : theme.colors.black};

    & > span {
      margin-left: ${theme.sizes[1]};
      font-weight: ${theme.fontWeights.regular};
      color: ${$necessity === 'required' ? theme.colors.alert400 : 'inherit'};
    }
  `}
`

const StyledCheckboxHelperText = styled.span<StyledCheckboxHelperProps>`
  ${({ theme, $isError, $isDisabled }) =>
    `
    margin-left: 1.75rem;
    cursor: default;
    font-weight: ${theme.fontWeights.regular};
    font-size: ${theme.fontSizes.bodySmall};
    line-height: ${theme.sizes[5]};
    color: ${
      $isError
        ? theme.colors.alert400
        : $isDisabled
        ? theme.colors.gray400
        : theme.colors.gray600
    }
  `}
`

const StyledCheckbox = styled.input<StyledCheckboxProps>`
  ${({ theme, $isError }) =>
    `
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent !important;
    width: ${theme.sizes[5]};
    height: ${theme.sizes[5]};
    cursor: pointer;
    transition: all ${theme.transitionTime};
    position: relative;

    &:focus-visible {
      outline: none;

      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        width: ${theme.sizes[5]};
        height: ${theme.sizes[5]};
        border-radius: ${theme.borderRadii.small};
        box-shadow: ${theme.colors.primary} 0px 0px 0px 0.125rem;
      }
    }

    &::before {
      content: "";
      display: inline-block;
      box-sizing: border-box;
      width: ${theme.sizes[5]};
      height: ${theme.sizes[5]};
      border-radius: ${theme.borderRadii.small};
      border: 0.15rem solid ${
        $isError ? theme.colors.alert400 : theme.colors.gray300
      };
      background: transparent;
      pointer-events: none;
      transition: all ${theme.transitionTime};
    }

    &:hover&::before {
      border-color: ${$isError ? theme.colors.alert400 : theme.colors.primary};
      box-shadow: ${theme.colors.primary100} 0px 0px 0px ${theme.sizes[1]};
    }

    &:checked::before {
      border-color: ${theme.colors.primary};
      background-color: ${theme.colors.primary};
    }

    &:disabled, &:disabled:hover {
      pointer-events: none;

      &::before {
        box-shadow: none;
        background-color: ${theme.colors.gray70};
        border-color: ${theme.colors.gray300};
      }
    }
  `}
`

const getCheckboxIconVarian = (variant: CheckboxVariant) => {
  switch (variant) {
    case 'indeterminate':
      return 'subtract'
    default:
      return 'check'
  }
}

export const Checkbox = ({
  name,
  label,
  value,
  helperText,
  active,
  onChange,
  variant,
  disabled,
  errorMessage,
  dataTestId,
  necessity,
  onBlur,
}: CheckboxProps) => {
  return (
    <StyledCheckboxContainer>
      <StyledCheckboxLabel htmlFor={value}>
        <StyledCheckboxWrapper>
          <StyledCheckbox
            id={value}
            name={name}
            checked={active}
            onChange={onChange}
            type="checkbox"
            role="checkbox"
            value={value}
            disabled={disabled}
            $isError={!!errorMessage}
            data-testid={dataTestId}
            onBlur={onBlur}
          />
          {active ? (
            <Icon
              name={getCheckboxIconVarian(variant)}
              size={4}
              color={disabled ? 'gray300' : 'white'}
            />
          ) : null}
        </StyledCheckboxWrapper>
        <StyledCheckboxLabelText $isDisabled={disabled} $necessity={necessity}>
          {label}
          {necessity === 'required' ? <span>*</span> : null}
        </StyledCheckboxLabelText>
      </StyledCheckboxLabel>
      {errorMessage || helperText ? (
        <StyledCheckboxHelperText
          $isError={!!errorMessage}
          $isDisabled={disabled}
        >
          {errorMessage || helperText}
        </StyledCheckboxHelperText>
      ) : null}
    </StyledCheckboxContainer>
  )
}
