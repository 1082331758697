export const fluidSize = (
  minSize: number,
  maxSize: number,
  minViewport = 320,
  maxViewport = 1920
): string => {
  const toParsedInt = (n: number) => n / (n * 0 + 1)

  return `calc(${minSize}px + ${toParsedInt(
    maxSize - minSize
  )} * ((100vw - ${minViewport}px) / ${toParsedInt(
    maxViewport - minViewport
  )}))`
}
