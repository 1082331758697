import { string } from 'yup'

type Options = { httpsOnly?: boolean }

const httpRegex = /^(?:https?:\/\/)([^/?\n]+)/i
const httpsRegex = /^(?:https:\/\/)([^/?\n]+)/i

export const isValidWebsiteURL = (
  message = 'this must be a valid website URL',
  { httpsOnly = false }: Options = {}
) =>
  string()
    .transform((a) => {
      if (a == null) return a

      if (!/^https?:\/\//.test(a)) {
        return httpsOnly ? `https://${a}` : `http://${a}`
      }

      return a
    })
    .test('is-website-url', message, async function isWebsiteUrl(value) {
      if (value == null) return true
      try {
        await string().url().validate(value)
        const matches = httpsOnly
          ? value.match(httpsRegex)
          : value.match(httpRegex)

        if (!matches) throw new Error()
        if (matches?.[1].indexOf('@') !== -1) {
          throw new Error()
        }

        return true
      } catch (e) {
        return this.createError({
          path: this.path,
          message: 'Must be a valid website URL',
        })
      }
    })
