import styled from 'styled-components'

export interface StyledNonSearchableProps {
  $disabled?: boolean
  $isPlaceholder?: boolean
}

export const StyledNonSearchable = styled.div<StyledNonSearchableProps>`
  ${({ theme, $disabled, $isPlaceholder }) => `
      pointer-events: ${$disabled ? 'none' : 'auto'};
      width: 100%;
      text-align: left;
      color: ${$isPlaceholder ? theme.colors.neutral300 : theme.colors.black};
      margin-right: ${theme.sizes.l};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      align-items: center;
    `}
`
