import { Styles } from '@percent/workplace-giving/types'

export const ErrorContainer: Styles = {
  display: 'flex',
  flexDirection: 'column',
  width: '100vw',
  height: '100vh',
  alignItems: 'center'
}

export const ErrorWrapper: Styles = {
  marginTop: '64px'
}
