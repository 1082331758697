import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

const LoaderWrapper = {
  boxSizing: 'border-box',
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexGrow: 1,
  width: '100%',
  height: '100%',
  minWidth: '100%',
  paddingTop: '20px'
}

const Content = {
  display: 'flex',
  width: '100%'
}

export const getStyles = (customPalette: ColorPaletteType) => ({
  LoaderWrapper,
  LoaderContentWrapper: {
    ...LoaderWrapper,
    paddingY: 0,
    paddingX: '24px',
    paddingBottom: '28px'
  },
  LoaderLogoBox: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    marginTop: '32px',
    marginBottom: '32px',

    '@media (min-width: 600px)': {
      marginTop: '36px'
    }
  },
  Logo: {
    transform: 'none',
    marginRight: '12px',
    borderRadius: '8px'
  },
  LoaderTitleBox: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  Content,
  FlexColumn: {
    ...Content,
    flexFlow: 'column',
    alignItems: 'flex-start',
    marginBottom: '16px'
  },
  FlexRow: {
    ...Content,
    flexFlow: 'row',
    alignItems: 'flex-start'
  },
  InputSkeleton: {
    width: '100%',
    height: '56px',
    marginBottom: '8px',
    backgroundColor: `white !important`,
    borderRadius: '8px',
    border: `1px solid ${customPalette.gray20}`
  },
  PresetValuesSkeleton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',

    '& > span': {
      width: '23%',
      height: '34px',
      borderRadius: '4px'
    }
  }
})
