import { Styles } from '@percent/workplace-giving/types'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const Title: Styles = {
  marginBottom: lemonadeTheme.sizes[4],
  fontSize: lemonadeTheme.sizes[10],
  fontWeight: lemonadeTheme.fontWeights.semiBold,
  lineHeight: lemonadeTheme.sizes[12],
  '@media (max-width: 768px)': {
    fontSize: lemonadeTheme.sizes[8],
    marginBottom: '0px'
  }
}

export const EventLocationDetails: Styles = {
  '& > div': {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: lemonadeTheme.sizes[2],
    marginBottom: lemonadeTheme.sizes[2]
  },

  '& > p': {
    marginY: lemonadeTheme.sizes[4],
    fontSize: lemonadeTheme.sizes[5],
    lineHeight: lemonadeTheme.sizes[6],
    fontWeight: lemonadeTheme.fontWeights.semiBold
  }
}
