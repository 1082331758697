import { environments } from '../constants/environments'
import { Terms } from '../context/auth/authContext/AuthContext.types'

import { Money, getApiDomainFromHostname, reassignUrlDomain } from '@percent/utility'

export const environment = process.env.NX_REACT_APP_ENV

type SupportedByColleagues = {
  id: string
  totalDonated: Money
}

export type Config = {
  urls: {
    appUrl: string
    baseUrl: string
    donationLinkBaseUrl: string
    helpCenterUrl: string
  }
  terms: Record<Terms, string>
  keys: {
    publicKey?: string
    zendeskWidgetKey?: string
    geolocation?: string
  }
  ids: {
    donationLinkRef: string
  }
  allowedMessageOrigins: {
    [K in typeof environments[keyof typeof environments]]: string[]
  }
  counterTimeoutInSec?: string
  supportedByColleagues: SupportedByColleagues[]
  fundraiserId?: string
  skills: { name: string; id: string }[]
  acceptedBannerImgFormats: string
  acceptedBannerImgFormatsHuman: string
  maxParticipantSpots: number
  acceptedReceiptFormats: string
  acceptedReceiptFormatsHuman: string
}

const supportedByColleagues = (): SupportedByColleagues[] => {
  if (environment === environments.PRODUCTION) {
    return [
      {
        id: 'organisation_000000C704YwrJIZHsHClSxGtIXQ8',
        totalDonated: {
          currency: 'AUD',
          amount: 5083700
        }
      },
      {
        id: 'organisation_000000C704ZwcOZxsUka8JOzZ3EfO',
        totalDonated: {
          currency: 'AUD',
          amount: 3151500
        }
      },
      {
        id: 'organisation_000000C704Q5szuovTAxvwl5ZUYce',
        totalDonated: {
          currency: 'AUD',
          amount: 907600
        }
      }
    ]
  }

  if (environment === environments.SANDBOX) {
    return [
      { id: 'sandbox_organisation_000000CAlXQqC4T0v1KS7LqNrJFZ7', totalDonated: { currency: 'USD', amount: 348100 } },
      { id: 'sandbox_organisation_000000CCHPwCBY6yPXbEZFzbjbSaJ', totalDonated: { currency: 'USD', amount: 276400 } },
      { id: 'sandbox_organisation_000000CAlkqp362q1XITlspdfAVYn', totalDonated: { currency: 'USD', amount: 222400 } }
    ]
  }

  return [
    {
      id: 'staging_organisation_000000C6pZ5HOicsrNIqADH2wWPyY',
      totalDonated: {
        currency: 'AUD',
        amount: 5083700
      }
    },
    {
      id: 'staging_organisation_000000CRZ4ks8fH7toOqbSEJfWgGx',
      totalDonated: {
        currency: 'AUD',
        amount: 3151500
      }
    },
    {
      id: 'staging_organisation_000000CRZ4hKY8ipM39x4RkdwP5ZF',
      totalDonated: {
        currency: 'AUD',
        amount: 907600
      }
    }
  ]
}

const skills = [
  { name: 'Accounting', id: 'accounting' },
  { name: 'Branding', id: 'branding' },
  { name: 'Business Development', id: 'business-development' },
  { name: 'Coaching', id: 'coaching' },
  { name: 'Communications', id: 'communications' },
  { name: 'Copywriting', id: 'copy' },
  { name: 'Data Analysis', id: 'data-analysis' },
  { name: 'Database Administration', id: 'database-administration' },
  { name: 'Design', id: 'design' },
  { name: 'Development', id: 'development' },
  { name: 'Digital Advertising', id: 'digital-advertising' },
  { name: 'Digital Marketing', id: 'digital-marketing' },
  { name: 'Engineering', id: 'engineering' },
  { name: 'Entrepreneurship', id: 'entrepreneurship' },
  { name: 'Event Planning', id: 'event-planning' },
  { name: 'Executive Leadership', id: 'executive-leadership' },
  { name: 'Finance', id: 'finance' },
  { name: 'Fundraising', id: 'fundraising' },
  { name: 'Graphic Design', id: 'graphic-design' },
  { name: 'Human Resources', id: 'human-resources' },
  { name: 'Information Technology', id: 'information-technology' },
  { name: 'Legal', id: 'legal' },
  { name: 'Management', id: 'management' },
  { name: 'Marketing', id: 'marketing' },
  { name: 'Organizational Design', id: 'organizational-design' },
  { name: 'Photography and Video', id: 'photography-and-video' },
  { name: 'Project Management', id: 'project-management' },
  { name: 'Public Relations', id: 'public-relations' },
  { name: 'Research', id: 'research' },
  { name: 'Sales', id: 'sales' },
  { name: 'Search Engine Marketing', id: 'search-engine-marketing' },
  { name: 'Social Media', id: 'social-media' },
  { name: 'Sound Editing', id: 'sound-editing' },
  { name: 'Strategy Consulting', id: 'strategy-consulting' },
  { name: 'Talent Recruitment', id: 'talent-recruitment' },
  { name: 'Training', id: 'training' },
  { name: 'Web Design', id: 'web-design' },
  { name: 'Web Development', id: 'web-development' },
  { name: 'Writing', id: 'writing' }
]

const appDomain = getApiDomainFromHostname()
const environmentsExcludedFromDynamicApiUrl = [environments.LOCAL, environments.TEST]

export const config: Config = {
  urls: {
    appUrl: environmentsExcludedFromDynamicApiUrl.includes(environment!)
      ? `${process.env.NX_REACT_APP_APP_BASE_URL}`
      : reassignUrlDomain(`${process.env.NX_REACT_APP_APP_BASE_URL}`, appDomain),
    baseUrl: environmentsExcludedFromDynamicApiUrl.includes(environment!)
      ? `${process.env.NX_REACT_APP_API_BASE_URL}`
      : reassignUrlDomain(`${process.env.NX_REACT_APP_API_BASE_URL}`, appDomain),
    donationLinkBaseUrl: `${process.env.NX_REACT_APP_DONATION_LINK_BASE_URL}`,
    helpCenterUrl: 'https://goodstackgiving.zendesk.com/hc/en-us'
  },
  terms: {
    'percent:privacy:2024-01-01': 'https://goodstack.io/legal/privacy-policy',
    'workplace:terms:2024-01-01': 'https://goodstack.io/legal/employee-donation-additional-terms'
  },
  keys: {
    publicKey: process.env.NX_REACT_APP_PARTNER_PUBLIC_KEY,
    zendeskWidgetKey: process.env.NX_REACT_APP_ZENDESK_KEY,
    geolocation: process.env.NX_REACT_APP_GEOLOCATION_API_KEY
  },
  ids: {
    donationLinkRef: process.env.NX_REACT_APP_DONATION_LINK_REFERENCE!
  },
  counterTimeoutInSec: process.env.NX_REACT_APP_WPG_COUNTER_TIMEOUT_IN_SEC,
  allowedMessageOrigins: {
    [environments.LOCAL]: ['*'],
    [environments.STAGING]: ['*'],
    [environments.TEST]: ['*'],
    [environments.SANDBOX]: [
      'https://sandbox-fundraising.poweredbypercent.com',
      'https://sandbox-donate.poweredbypercent.com',
      'https://sandbox-donate.goodstack.io',
      'https://sandbox-giving.poweredbypercent.com'
    ],
    [environments.QA]: [
      'https://qa-fundraising.poweredbypercent.com',
      'https://qa-donate.poweredbypercent.com',
      'https://qa-donate.goodstack.io',
      'https://qa-giving.poweredbypercent.com'
    ],
    [environments.PRODUCTION]: [
      'https://fundraising.poweredbypercent.com',
      'https://donate.poweredbypercent.com',
      'https://donate.goodstack.io',
      'https://giving.poweredbypercent.com'
    ]
  },
  supportedByColleagues: supportedByColleagues(),
  fundraiserId: process.env.NX_REACT_APP_FUNDRAISER_ID,
  acceptedBannerImgFormats: '.jpg,.jpeg,.png',
  acceptedBannerImgFormatsHuman: '.jpg, .jpeg, .png',
  acceptedReceiptFormats: '.jpg,.jpeg,.png,.pdf',
  acceptedReceiptFormatsHuman: '.jpg, .jpeg, .png, .pdf',
  maxParticipantSpots: 100000,
  skills
}
