import React from 'react'
import styled from 'styled-components'
import { BadgeProps, BadgeVariant, StyledBadgeProps } from './badge.types'
import { Icon } from '../icon'
import { Color } from '../../theme'

const IconColorMap: Record<BadgeVariant, Color> = {
  default: 'gray800',
  informative: 'info400',
  positive: 'success400',
  warning: 'warning400',
  critical: 'alert400',
}

const IconBackgroundColorMap: Record<BadgeVariant, Color> = {
  default: 'gray80',
  informative: 'info100',
  positive: 'success100',
  warning: 'warning100',
  critical: 'alert100',
}

export const StyledBadge = styled.span<StyledBadgeProps>`
  ${({ theme, $variant, $icon }) =>
    `
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    height: ${theme.sizes[6]};
    padding-right: ${theme.sizes[2]};
    padding-left: ${$icon ? theme.sizes[1] : theme.sizes[2]};
    padding-top: ${theme.sizes[1]};
    padding-bottom: ${theme.sizes[1]};
    border-radius: ${theme.borderRadii.medium};
    background-color: ${theme.colors[IconBackgroundColorMap[$variant]]};

    font-size: ${theme.fontSizes.bodySmall};
    font-weight: ${theme.fontWeights.medium};
    color: ${theme.colors.gray800};
    white-space: nowrap;
    &:last-child {
      margin-right: 0;
    }
    `}
`

export const Badge = ({
  variant = 'default',
  icon,
  children,
  testId = 'badge',
}: BadgeProps) => (
  <StyledBadge $variant={variant} $icon={icon} data-testid={testId}>
    {icon ? <Icon name={icon} size={5} color={IconColorMap[variant]} /> : null}
    {children}
  </StyledBadge>
)
