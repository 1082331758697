import { Combobox } from '@headlessui/react'
import { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { Option, OptionGroup } from '../option'
import { SelectOption, SelectOptionGroup } from '../option.types'

const isOptionGroup = (
  opt: SelectOption | SelectOptionGroup
): opt is SelectOptionGroup => 'title' in opt

export const useAsyncSelect = (data: {
  options: (SelectOption | SelectOptionGroup)[]
  placeholder?: string
  defaultValue?: SelectOption
  setQuery: (query: string) => void
  query: string
  addOptionKeepQuery?: boolean
  noResultsFoundText?: string
  addOptionLabel?: string
  addOptionValue?: string
}) => {
  const {
    options,
    placeholder,
    defaultValue,
    query,
    setQuery,
    addOptionKeepQuery,
    noResultsFoundText = 'No results found',
    addOptionLabel,
    addOptionValue = '',
  } = data

  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
    defaultValue ?? null
  )
  const [addingOption, setAddingOption] = useState(false)
  const [focused, setFocused] = useState(false)
  const [lastQuery, setLastQuery] = useState('')
  const [checkAdding, setCheckAdding] = useState(false)
  const inputRef = useRef<HTMLInputElement>() as RefObject<HTMLInputElement>

  useEffect(() => {
    if (selectedOption) {
      setLastQuery('')
      setAddingOption(false)
    }
  }, [selectedOption])

  useEffect(() => {
    if (addingOption && addOptionLabel) {
      setSelectedOption({
        value: addOptionValue,
        label: addOptionLabel,
      })
    }
  }, [addingOption, addOptionLabel, addOptionValue])

  useEffect(() => {
    if (defaultValue) {
      setSelectedOption(defaultValue)
    }
  }, [defaultValue])

  const setValue = (e: SelectOption | null) => {
    setSelectedOption(e)
    if (!e) setLastQuery('')
  }

  useEffect(() => {
    if (
      addOptionKeepQuery &&
      addingOption &&
      !focused &&
      lastQuery &&
      inputRef.current &&
      inputRef.current.value === ''
    ) {
      inputRef.current.value = lastQuery
    }
  }, [addOptionKeepQuery, addingOption, focused, lastQuery, checkAdding])

  const renderOption = (option: SelectOption) => {
    return (
      <Combobox.Option
        key={option.value}
        value={option}
        style={{
          listStyle: 'none',
          marginLeft: 0,
          paddingLeft: 0,
          border: 'none',
        }}
      >
        {({ active }) => <Option {...option} isActive={active}></Option>}
      </Combobox.Option>
    )
  }

  /**
   * Responsible for rendering and managing the state of results
   * list based on search query.
   */
  const renderElements = useMemo(() => {
    if (!options.length) {
      return <div style={{ padding: '1rem' }}>{noResultsFoundText}</div>
    }

    const resultsMap = options.map((opt) =>
      isOptionGroup(opt) ? (
        opt.options.length === 0 ? null : (
          <OptionGroup key={opt.title} title={opt.title}>
            {opt.options.map(renderOption)}
          </OptionGroup>
        )
      ) : (
        renderOption(opt)
      )
    )

    if (resultsMap.length === 0) {
      return <div style={{ padding: '1rem' }}>{noResultsFoundText}</div>
    }

    return resultsMap
  }, [noResultsFoundText, options])

  const inputProps = {
    ref: inputRef,
    placeholder: placeholder,
    value: query,
    onFocus: () => setFocused(true),
    onBlur: () => setFocused(false),
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      if (selectedOption?.label !== e.target.value) {
        setQuery(e.target.value.trim())
        if (focused) setLastQuery(e.target.value.trim())
      }
      if (!focused) setCheckAdding(!checkAdding)
    },
    displayValue: (selectedOption: SelectOption) =>
      selectedOption?.label ?? lastQuery,
  }

  const triggerProps = {
    onClick: () => inputRef.current?.focus(),
  }

  // Used in preventing selection using spacebar when searching.
  const appendToSearch = (key: string) => {
    if (!inputRef.current || inputRef.current.value === '') return
    inputRef.current.value = inputRef.current.value + key
  }

  const onAddButton = () => {
    setAddingOption(true)
  }

  return {
    triggerProps,
    value: selectedOption,
    inputProps,
    renderElements,
    onAddButton,
    setValue,
    appendToSearch,
  }
}
