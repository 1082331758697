import React from 'react'
import styled from 'styled-components'
import { HeadingProps, StyledHeadingProps } from './heading.types'

export const StyledHeading = styled.span<StyledHeadingProps>`
  ${({ theme, $level, align }) =>
    `
    margin: 0;
    font-size: ${theme.fontSizes[$level]};
    font-weight: 500;
    color: ${theme.colors.gray900};
    text-align: ${align}
    `}
`

export const Heading = ({
  level,
  align,
  as,
  children,
  ...props
}: HeadingProps) => {
  return (
    <StyledHeading as={as || level} $level={level} align={align} {...props}>
      {children}
    </StyledHeading>
  )
}
