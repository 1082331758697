import React from 'react'
import styled from 'styled-components'
import { Spinner } from '../spinner'

const StyledLoaderContainer = styled.div<{ isFullScreen: boolean }>`
  ${({ isFullScreen }) => `
    position: ${isFullScreen ? 'absolute' : 'relative'};
    top: 50%;
    left: 50%;
    transform: ${isFullScreen ? 'translate(-50%, -50%)' : 'none'};
  `}
`

export const Loader = ({ isFullScreen = true }: { isFullScreen?: boolean }) => {
  return (
    <StyledLoaderContainer data-testid="loading" isFullScreen={isFullScreen}>
      <Spinner size="m" />
    </StyledLoaderContainer>
  )
}
