import { theme as lemonadeTheme } from '@percent/lemonade'
import { ColorPaletteType } from '@percent/workplace-giving/context/colorTheme/colorThemeContextController/ColorThemeContextController'

export const getStyles = (customPalette: ColorPaletteType) => ({
  BannerFallback: {
    backgroundColor: customPalette.gray20,
    borderRadius: '3px',
    '@media (min-width: 425px)': {
      height: '288px'
    }
  },
  Banner: {
    width: '100%',
    border: '1px solid',
    borderColor: customPalette.gray90,
    borderRadius: lemonadeTheme.borderRadii.large,
    height: 'auto',
    objectFit: 'cover',

    '@media(min-width: 425px)': {
      height: '288px'
    }
  }
})
