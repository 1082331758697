import React from 'react'
import styled from 'styled-components'

const StyledCardWrapper = styled.div`
  ${({ theme }) =>
    `
      display: flex;
      flex-flow: row;
      flex-wrap: nowrap;
      border-radius: ${theme.sizes[2]};
      background-color: ${theme.colors.white};
      box-shadow: 0px 2px 16px 0px rgba(13, 13, 13, 0.08);
      overflow: hidden;
      width: 100%;
    `}
`

export const Card = ({ children }: { children: React.ReactNode }) => (
  <StyledCardWrapper>{children}</StyledCardWrapper>
)
