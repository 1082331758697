import countries from 'i18n-iso-countries'
import * as Sentry from '@sentry/react'
import enLocale from 'i18n-iso-countries/langs/en.json'

export const loadCountryData = async () => {
  try {
    // Register the English locale for country names (currently only en.json is used)
    countries.registerLocale(enLocale)
  } catch (error) {
    Sentry.captureException(error)
  }
}

loadCountryData()

// eslint-disable-next-line import/no-default-export
export default countries
