import styled from 'styled-components'

export const StyledSelect = styled.div`
  ${({ theme }) => `
    position: relative;
    width: 100%;
    font-size: ${theme.fontSizes.bodySmall};

    @media only screen and (max-device-width: 600px) {
      -webkit-appearance: none;
      font-size: ${theme.sizes[4]};
    },
  `}
`
