import { useContext } from 'react'

import { ColorThemeContext } from '@percent/workplace-giving/context/colorTheme/colorThemeContext/ColorThemeContext'

export const useColorTheme = () => {
  const context = useContext(ColorThemeContext)

  if (context === undefined) {
    throw new Error('useColorTheme must be used within a ColorThemeContext')
  }

  return context
}
