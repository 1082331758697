import React from 'react';
import styled from 'styled-components';
import { LabelProps, StyledLabelProps } from './label.types';

export const StyledLabel = styled.label<StyledLabelProps>`
  ${({ theme, $necessity }) =>
    `
    font-size: ${theme.fontSizes.bodySmall};
    font-weight: ${theme.fontWeights.medium};
    color: ${theme.colors.black};

    & > span {
      margin-left: ${theme.sizes[1]};
      font-weight: ${theme.fontWeights.regular};
      color: ${
        $necessity === 'optional' ? theme.colors.gray600 : theme.colors.alert400
      };
    }
    `}
`;

export const Label = ({ htmlFor, necessity, children }: LabelProps) => (
  <StyledLabel $necessity={necessity} htmlFor={htmlFor}>
    {children}
    {necessity === 'required' ? <span>*</span> : null}
    {necessity === 'optional' ? <span>(optional)</span> : null}
  </StyledLabel>
);
