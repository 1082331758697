import React, { useRef } from 'react'
import styled, { CSSProp, css } from 'styled-components'
import { useButton } from '@react-aria/button'
import {
  ButtonTextProps,
  ButtonTextVariant,
  StyledButtonTextProps,
} from './button-text.types'
import { Icon } from '../icon'
import { Theme } from '../../theme'
import { styledVariant } from '../../util'

const StyledButtonLabel = styled.span`
  ${({ theme }) => css`
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: ${theme.sizes[1]};
  `}
`

const colors: Record<ButtonTextVariant, (obj: { theme: Theme }) => CSSProp> = {
  default: ({ theme }) => css`
    color: ${theme.colors.gray600};

    ${StyledButtonLabel} {
      text-decoration-color: ${theme.colors.gray600};
    }

    &:hover {
      color: ${theme.colors.primary400};

      ${StyledButtonLabel} {
        text-decoration-color: ${theme.colors.primary400};
      }
    }

    &:focus {
      &:before {
        box-shadow: 0 0 0 1px ${theme.colors.primary400};
      }

      ${StyledButtonLabel} {
        text-decoration-color: ${theme.colors.primary400};
      }
    }

    &:active {
      color: ${theme.colors.primary400};
    }
  `,
  primary: ({ theme }) => css`
    color: ${theme.colors.primary400};

    ${StyledButtonLabel} {
      text-decoration-color: ${theme.colors.primary400};
    }

    &:hover {
      color: ${theme.colors.primary600};

      ${StyledButtonLabel} {
        text-decoration-color: ${theme.colors.primary600};
      }
    }

    &:focus {
      &:before {
        box-shadow: 0 0 0 1px ${theme.colors.primary600};
      }

      ${StyledButtonLabel} {
        text-decoration-color: ${theme.colors.primary600};
      }
    }

    &:active {
      color: ${theme.colors.primary600};
    }
  `,
}

const StyledButtonText = styled.button<StyledButtonTextProps>`
  ${styledVariant('$variant', colors)};
  ${({ theme }) =>
    `
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row;
      gap: 0.125rem;
      padding: 0;
      position: relative;
      border: none;
      cursor: pointer;
      outline: 0;
      font-weight: 500;
      font-size: ${theme.fontSizes.buttonText};
      white-space: normal;
      background: transparent;
      text-align: left;
      line-height:  ${theme.sizes[5]};

      &:disabled {
        pointer-events: none;
         color: ${theme.colors.gray400};
      }

      &:focus {
        &:before {
          content: '';
          position: absolute;
          left: -4px;
          right: -4px;
          bottom: -4px;
          top: -4px;
          border-radius: ${theme.borderRadii.medium};
       }
      }

      &:active {
        &:before {
          opacity: 0;
        }
      }

      svg {
        min-width: ${theme.sizes[5]};
        min-height: ${theme.sizes[5]};
      }

      & > * {
        line-height:  ${theme.sizes[5]};
      }
    `}
`

export const ButtonText = (props: ButtonTextProps) => {
  const { disabled, icon, children, variant = 'default' } = props
  const ref = useRef(null)
  const { buttonProps } = useButton(
    {
      ...props,
      isDisabled: disabled,
    },
    ref
  )

  return (
    <StyledButtonText $variant={variant} {...buttonProps} ref={ref}>
      {icon && <Icon name={icon} color="inherit" size={5} />}
      <StyledButtonLabel>{children}</StyledButtonLabel>
    </StyledButtonText>
  )
}
