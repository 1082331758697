import {
  Opportunity,
  OpportunityLocationType
} from '@percent/workplace-giving/api/search/searchOpportunities/searchOpportunities.types'

export const formatFullOfflineOpportunityAddress = (opportunity: Pick<Opportunity, 'location'>) => {
  if (opportunity.location.type !== OpportunityLocationType.OFFLINE) return undefined

  const addressLine =
    opportunity.location.locationDisplayName ??
    [
      opportunity.location.locationName,
      opportunity.location.addressLineOne,
      opportunity.location.addressLineTwo,
      opportunity.location.city
    ]
      .filter(arg => arg?.trim()?.length)
      .join(', ')

  return `${addressLine}, ${opportunity.location.zipCode} ${opportunity.location.country}`
}

export const formatShortOfflineOpportunityAddress = (opportunity: Pick<Opportunity, 'location'>) => {
  if (opportunity.location.type !== OpportunityLocationType.OFFLINE) return undefined

  const maybeCity = opportunity.location.city?.trim()?.length ? opportunity.location.city : undefined
  const maybeDisplayName = opportunity.location.locationDisplayName?.trim()?.length
    ? opportunity.location.locationDisplayName
    : undefined

  return [maybeCity ?? maybeDisplayName, opportunity.location.country].filter(Boolean).join(', ')
}
